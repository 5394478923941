import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { CloseCircleOutlined } from "@ant-design/icons";
import userContext from "../../components/context/UserContext";

const PlayGamesIFrame = () => {
  const params = useParams();
  const [url, setUrl] = useState("");
  const { state } = useContext(userContext);
  const { games } = state;

  console.log(state);

  useEffect(() => {
    if (games.length > 0) {
      var url = games.filter((urlItem) => urlItem.gameid === params.slug)[0]
        .gameurl;
      console.log("Hi", params.slug);
      console.log("url ", url);
      setUrl(url);
    }
  }, [games]);

  return (
    <PlayGamesIFrameStyled>
      <iframe
        src={url}
        height={window.innerHeight}
        width={window.innerWidth}
        frameBorder="0"
        scrolling="no"
      ></iframe>
      <div>
        <Link to={`/game/${params.slug}`}>
          <CloseCircleOutlined className="icon-close" />
        </Link>
      </div>
    </PlayGamesIFrameStyled>
  );
};

export default PlayGamesIFrame;

const PlayGamesIFrameStyled = styled.div`
  min-height: 80vh;
  div {
    z-index: 20;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .icon-close {
    z-index: 5001;
    cursor: pointer;
    color: cyan;
    font-size: 20px;
  }
`;
