import { Spin } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Main from "../../components/Main/Main";
import logogamofyy from "../../assets/images/logo.png";
import igpl from "../../assets/images/GPL.png";
import axios from "axios";
// import ModalView from "../../components/Modal/Moda";
import { useCookies } from "react-cookie";


const HomePage = () => {
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [cookies, setCookie] = useCookies(["msisdn"]);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  console.log(cookies, "this is cookies here --==>>");

  // useEffect(() => {
  //   const FetchUserStatus = async () => {
  //     try {
  //       // network === "NIG_AIRTEL" ? "NIG_AIRTEL_SE" : "MTN"
  //       // console.log(queryParams.get("msisdn"), "this is query params here --==>>>");

  //        const msisdn = queryParams.get("msisdn");
  //       console.log(msisdn, "this is msisdn")
  //       const encodedMsi = encodeURIComponent(`${msisdn}`);
        
  //       const response = await axios(
  //         // `http://mtnotify.gameofyy.com/user?ani=${queryParams.get(
  //         //   "msisdn"
  //         // )}&operator=${
  //         //   queryParams.get("network") === "NIG_AIRTEL"
  //         //     ? "NIG_AIRTEL_SE"
  //         //     : "MTN"
  //         // }&isSubsubscribe=false`
  //         `/users?ani=${encodedMsi}&operationId=${
  //           queryParams.get("operationId")
  //         }&status=${
  //           queryParams.get("status")
  //         }`
  //       );
  //       // console.log("Fetch Ani Status with msisdin users query ", response.data);
  //       return response.data;
  //     } catch (err) {
  //       console.log(err, "this is error here -===-=-=========-=-=-=--=-=--=-=-=-=>");

  //       const data = err.response.data;
  //       //need to change status to result here ==>>
  //         if(data.result === 0){
  //           setShowModal(true);
  //           setMessage(data.message);
  //           setTitle("Not a Subscriber");
  //           navigate("/subscribe");
        
  //       };
  //     };
  //   };

  //   if (queryParams.get("msisdn") === false) {
  //     setShowModal(true);
  //     setTitle("Invalid User");
  //     setMessage("You are not a valid user for this Service");
  //   }

  //   if (queryParams.get("msisdn") !== false)
  //     FetchUserStatus().then((resp) => {
  //       // console.log(resp, "Home page res here");
  //       //need to change status to result =>>>>
  //       if (resp.result === 1) {
  //         // localStorage.removeItem("tansuui");
  //         setCookie("msisdn", queryParams.get("msisdn"), {
  //           path: "/",
  //           maxAge: 86400,
  //         });
  //         return navigate("/redirect");
  //       }
  //     });
  // }, []);
  const params = useParams();
  // console.log(params);
  const search = useLocation().search;
  let ext_id = new URLSearchParams(search).get("ext_id");
  // console.log("ext_id ", ext_id);

  const queryParams = new URLSearchParams(window.location.search);
  // console.log("location==> ANI ", queryParams.get("msisdn"));
  // console.log("location==> NETWORK ", queryParams.get("network"));


  // console.log(params.slug, "This is Params.slug here -->>>>");

  // if (ext_id === undefined || ext_id === null)
  //   ext_id = Math.floor(Math.random() * 1000000000);

  // console.log(ext_id);

  return (
    <StyledHomePage>
      <Suspense
        fallback={
          <>
            <Spin />
          </>
        }
      >
        {params.slug === "igpl" ? (
          <Main 
          logo={igpl} 
          serviceType={params.slug} ext_id={ext_id} />
        ) : (
          <>
            <Main
              serviceType={params.slug}
              logo={logogamofyy}
              ext_id={ext_id}
              msisdn={queryParams.get("msisdn")}
              network={queryParams.get("network")}
            />
            {/* <ModalView message={message} title={title} showModal={showModal} /> */}
          </>
        )}
      </Suspense>
    </StyledHomePage>
  );
};

export default HomePage;

const StyledHomePage = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
`;
