import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import mcposter from ''
import '../../css/Login.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cookies from "js-cookie"
// mcfunny.madfunny.co.za
// https://madfunny.co.za/DoiRedirect?subscription_id=2123446562&msisdn=27680633950&ext_ref=testing_ref
const Login = ({ service_id, serviceName }) => {
  const [q] = useSearchParams();
  const [ani, setAni] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let ext_ref = q.get("ext_ref");

  const redirectUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(
      'https://callback.bubblebobble.co.za/api/subscribe',
      {
        "msisdn": ani,
        "ext_ref": ext_ref,
        "channel": "WAP",
        "svc_id": service_id
      }
    ).then(res => {
      console.log(res.data, "got response here  =>>>>>>>>");
      // const subsubscription_id = res.data.subscriptionId;
      if (res.data.result === 1) {
        // test url https://sdp-s-vas-payment.telkom.co.za
        // prod url https://sdp-p-vas-payment.telkom.co.za
        return window.location.replace(res.data.redirectUrl);
      }
      setLoading(false);
      if (res.data.result === 2) {
        cookies.set("bubbo-ani", ani, {
          expires: 7
      });
        navigate(`/redirect?service=${serviceName}&msisdn=${ani}&result=active`)
      }

    }).catch(err => {
      console.log(err)
      setLoading(false);
      toast.error(err.response?.data?.error_message || err.response?.data?.msg)
    })
  }
  return (
    <div className='login-container' style={{ fontFamily: "'DM Sans', sans-serif" }}   >

      <div className='
       md:mx-5'>
        <form onSubmit={redirectUser} className=' max-w-lg rounded-md' style={{
          width: '100%',
          backgroundColor: 'rgba(0.7, 0.7, 0.7, 0.5)', // Set a transparent white background
          backdropFilter: 'blur(5px)',
          padding: '20px',
        }}>
          <h1 className='text-white  text-2xl  md:text-4xl  mt-5'>Welcome To
            {" Bubbob Games"}
          </h1>
          <p className='text-white text-md mt-1 font-medium '>
            Play unlimited! games
          </p>
          <h1 className='mt-4 text-white text-md font-bold'>Enter Your Number</h1>
          <input type="text" class="bg-gray-50 focus:outline-none text-gray-900 mt-2 text-sm py-3 block w-full p-2.5  dark:border-gray-600  dark:gray-900"

            placeholder='Enter your phone number : eg 812xxxxxx'
            id="phoneInput"
            name="ani"
            value={ani}
            onChange={(e) => setAni(e.target.value)}
            required />
          <div class="flex items-center mb-4 mt-3">
            <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 focus:outline-none border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label for="default-checkbox" class="ml-2 text-md font-medium text-white dark:text-gray-300">I agreed to the Terms and Conditions</label>
          </div>

          <button
            disabled={loading}
            type="submit"
            class="text-white bg-[#0D6EFD] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-7 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            {loading ? "Loading..." : "JOIN"}
          </button>

        </form>
      </div>
      {/* <ToastContainer /> */}
    </div>

  );
};

export default Login;
